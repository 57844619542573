import React, {useEffect, useState} from "react";
import { useLocale } from "../../utils/LocaleContext";
import { useBreadcrumbs } from "../../utils/BreadcrumbsContext";
import axios from "axios";
import { Link } from "react-router-dom";

import './Category.scss';

import icosvg from '../../../assets/ico/ico';
import { useTranslation } from "react-i18next";

const Category = () => {

    const { country, lang } = useLocale();
    const hrefLocale = `${country}-${lang}`;
    const [arrCategory, setCategory] = useState([]);
    const { updateBreadcrumbs } = useBreadcrumbs();
    const { t } = useTranslation();
    const [ isCategorySee, setCategorySee ] = useState(false);
    
    useEffect(() => {
        if (!lang) { return; }
        axios
            .get('https://mendeleev.store/core/code/category/category.php', {
                params: {lang: lang}
            })
            .then(response => {
                setCategory(response.data);
            }).catch(error => {
                console.error("Error fetching data:", error);
            })
    }, [lang]);

    const handleCategoryClick = (category) => {
        if (updateBreadcrumbs && typeof updateBreadcrumbs === 'function') {
            updateBreadcrumbs([
                { name: category.name, href: `/${hrefLocale}/catalog/${category.code}` },
            ]);
        }
    };

    const handleAllCategoryClick = () => {
        setCategorySee(true);
    }

    return(
        <div className="defCategoryLeft">
            <div onClick={() => handleAllCategoryClick()} className={isCategorySee ? ('openAllCategory none'):('openAllCategory')}>{t('open-all-catalog')}</div>
                <ul className={isCategorySee && ("show")}>
                    {arrCategory.map((item, index) => (
                        <li key={'category-' + index}><Link to={'/'+hrefLocale+'/catalog/' + item.code} onClick={() => handleCategoryClick(item)}><span>{item.name}</span> <span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'arrow-n-line').svg }}></span></Link></li>
                    ))}
                </ul>
        </div>
    );
};

export default Category;