import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useLocale } from "../../utils/LocaleContext";

import './PageCaption.scss';

const PageCaption = ({arrHref, titleH1}) => {

    const { country, lang } = useLocale();
    const logoHref = `${country}-${lang}`;

    const lastItem = arrHref[arrHref.length - 1];
    const { t, i18n } = useTranslation();
    if (!i18n.isInitialized) { return <div>Loading translations...</div>; }
    return(
        <div className="compUIpageCaption">
            <div className="wrap">
                <div className="breadCrumbs">
                    <ul>
                        <li key="bread-main-home"><Link to={`../${logoHref}`}>{t('home')}</Link></li>
                        <li key="bread-main-home-arrow">&#8250;</li>
                        <li key="bread-main-catalog"><Link to={`../${logoHref}/catalog`}>{t('all-catalog')}</Link></li>
                        <li key="bread-main-catalog-arrow">&#8250;</li>
                        {arrHref.map((item, index) => (
                            <React.Fragment key={'bread-fragment-' + index}>
                                { index < arrHref.length - 1 ? (<li key={'bread-link-' + index}><Link to={item.href}>{item.name}</Link></li>) : ( <li key={'bread-final-' + index}>{item.name}</li>) }
                                { index < arrHref.length - 1 && <li key={'bread-arrow-' + index}>&#8250;</li>}
                            </React.Fragment>
                        ))}
                    </ul>
                </div>
               <h1>{lastItem?.name || titleH1}</h1> 
            </div>
        </div>
    );
};

export default PageCaption;