import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useLocale } from "../../utils/LocaleContext";
import { useTranslation } from "react-i18next";

import axios from "axios";

import PageCaption from "../../ui/PageCaption/PageCaption";

import './PageCart.scss';

import icosvg from '../../../assets/ico/ico';
const ShopIcon = () => { return( <span className="icon" dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'shop').svg }} /> )}


const PageCartItem = ({item, arrWall, lang, country, updateCart}) => {

    const product = arrWall[item.ext];
    const productSku = product ? product.sku.find(sku => sku.sku === item.sku) : null;

    if(productSku){
        const { price, sale, amount } = productSku;

        const changeQuantityPlus = () => {
            updateCart(item.ext, item.sku, item.quant + 1);
        }

        const changeQuantityMinus = () => {
            if (item.quant > 1) {
                updateCart(item.ext, item.sku, item.quant - 1);
            }
        }

        return(
            <li key={`${item.ext}_${item.sku}`}>
                <div className="icon"><Link to={`/${country}-${lang}/goods/${product.code}?sku=${item.sku}`}><img alt={product.name} src={product.icon} /></Link></div>
                    <div className="info">
                        <div className="title">
                            <Link to={`/${country}-${lang}/goods/${product.code}?sku=${item.sku}`}>{product.name}</Link>
                            <p>{product.synonyms}</p>
                        </div>
                        <div className="sku"> <span>{productSku.name}</span> </div>
                        <div className="price">
                                { sale > 0 ? (<div className="old"><del>${amount}</del> <span>- ${sale}</span></div>) : (<></>)}
                                <p>${price}</p>
                            </div>
                            <div className="quantity">
                                <div className="box">
                                    <div className="ctrl" onClick={changeQuantityMinus}>-</div>
                                        <div className="qu">{item.quant}</div>
                                    <div className="ctrl" onClick={changeQuantityPlus}>+</div>
                                </div>
                            </div>
                            <div className="price amount">
                                { sale > 0 ? (<div className="old"><del>${amount * item.quant}</del> <span>- ${sale * item.quant}</span></div>) : (<></>)}
                                <p>${(price * item.quant).toFixed(2)}</p>
                            </div>
                    </div>
                </li>
        ); 
    }

    return null;
}

const PageCart = () => {

    const { t } = useTranslation();
    const { country, lang } = useLocale();

    const [ arrWall, setWall ] = useState([]);
    const [ arrCart, setArrCart ] = useState([]);

    const updateCart = (ext, sku, newQuant) => {
        const updatedCart = arrCart.map(item => {
            if(item.ext === ext && item.sku === sku){
                return {...item, quant: newQuant}
            }
            return item
        });
        setArrCart(updatedCart);
        localStorage.setItem('cart_order', JSON.stringify(updatedCart));
    }

    useEffect(() => {
        if (!lang) return;

        const cartOrder = JSON.parse(localStorage.getItem('cart_order') || []);
        setArrCart(cartOrder);

        const requestData = {
            lang: lang,
            items_ext: cartOrder
        }

        axios
            .post('https://mendeleev.store/core/code/wall/wall.goods.php', requestData, {headers: { 'Content-Type': 'application/json'}})
            .then(response => {
                setWall(response.data);
            })
            .catch(error => {
                console.error("Error fetching data:", error);
            })
    }, [lang]);

    const calculateTotal = () => {
        let totalAmount = 0;
        let totalSale = 0;
        let totalPrice = 0;
        arrCart.forEach(item => {
            const wallItem = arrWall[item.ext];
            if(wallItem){
                const skuItem = wallItem.sku.find(sku => sku.sku === item.sku);

                if(skuItem){
                    const price = parseFloat(skuItem.price);
                    const sale = parseFloat(skuItem.sale);
                    const amount = parseFloat(skuItem.amount);

                    totalAmount += amount * item.quant;
                    totalSale += sale * item.quant;
                    totalPrice += price * item.quant;
                }
            }
        });
        return { totalAmount, totalSale, totalPrice };
    };

    const { totalAmount, totalSale, totalPrice } = calculateTotal();

    const breadcrumbs = [{name: t('your-cart'), href: '/cart'}];

    return (
        <div className="contPageCart">
            <div className="wrap">
                <PageCaption arrHref={breadcrumbs} /> 

                <div className="gridCart">
                    <div className="sideInfo">
                        <div className="boxGoddsList">
                            <h3>{t('your-product-list')}</h3>
                            <ul>
                                {arrCart.map((item) => (
                                    <PageCartItem 
                                        key={`${item.ext}_${item.sku}`}
                                        item = {item}
                                        arrWall = {arrWall}
                                        lang = {lang}
                                        country = {country}
                                        updateCart = {updateCart}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="sideTotal">
                        <div className="boxAmount">
                            <div className="price">
                                { totalSale > 0 ? (<div className="sale"><del>${totalAmount}</del> <span>Save: ${totalSale}</span></div>) : (<></>)}
                                <div className="amount"><sup>$</sup>{totalPrice}</div>
                            </div>
                            <div className="char">
                                <ul>
                                    <li><span>Shipping</span> <span>Read item description or contact seller for shipping options. See detailsfor shipping</span></li>
                                    <li><span>Delivery</span> <span>Varies</span></li>
                                    <li><span>Returns</span> <span>30 days returns. Buyer pays for return shipping.</span></li>
                                </ul>
                            </div>
                            <button><ShopIcon/> {t('send-to-order')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageCart;