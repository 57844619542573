import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import HeaderGradient from './components/ui/Header/HeaderGradient';
import Footer from './components/ui/Footer/Footer';

import PageHome from './components/containers/PageHome/PageHome';
import PageCatalog from './components/containers/PageCatalog/PageCatalog';
import PageGoods from './components/containers/PageGoods/PageGoods';
import PageCart from './components/containers/PageCart/PageCart';

import { LocaleProvider } from './components/utils/LocaleContext';
import { BreadcrumbsProvider } from './components/utils/BreadcrumbsContext';

function App() {
    console.log('run page app');
    return (
        <div className="App">
            <BreadcrumbsProvider>
            <Router>
                <LocaleProvider> <HeaderGradient /> </LocaleProvider>
                    <Routes>
                        <Route path="/" element={<Navigate to={ localStorage.country + '-' + localStorage.lang} />} />
                        <Route path="/:local" element={ <PageHome /> } />
                        <Route path="/:local/catalog/" element={<PageCatalog />} />
                        <Route path="/:local/catalog/:cat" element={<PageCatalog />} />
                        <Route path="/:local/goods/:goods" element={<LocaleProvider> <PageGoods /> </LocaleProvider>} />
                        <Route path="/:local/cart/" element={<LocaleProvider> <PageCart/> </LocaleProvider>} />
                    </Routes>
                <Footer />
            </Router>
            </BreadcrumbsProvider>
        </div>
    );
}

export default App;
