import React, { useEffect, useState } from "react";

import { useLocale } from "../../utils/LocaleContext";
import { useTranslation } from "react-i18next";

import axios from "axios";

import icoSVG from "../../../assets/ico/ico";

import './Wall.scss';
import { Link } from "react-router-dom";


const WallItem = ({item, country, lang, icoSVG, t}) => {
    
    const firstSku = item.sku?.[0];
    const price = firstSku ? firstSku.price : '';
    const sale = firstSku?.sale > 0;
    const saleAmount = firstSku?.amount;
    const saleValue = firstSku?.sale;

    const renderIcon = (iconName) => (
        <span dangerouslySetInnerHTML={{ __html: icoSVG.find(icon => icon.name === iconName)?.svg }}></span>
    );

    return(
        <li key={item.code}>
            <div className="ico">
                <Link to={`../${country}-${lang}/goods/${item.code}`}>
                    {item.icon ? <img src={item.icon} alt={item.name} /> : <span></span>}
                </Link>
                <div className="boxOffer">
                    {sale && (<span className="sale">{t("sale")}</span>)}
                    {/* <span className="best">{t("best-seller")}</span> */}
                </div>
                <div className="ctrl">
                    <div className="circleCtrl">{renderIcon('heart')}</div>
                    {/* <div className="circleCtrl">{renderIcon('scales')}</div> */}
                </div>
            </div>

            <div className="info">
                <div className="price">
                    <div className="amount">
                        <sup>$</sup><span>{price}</span>
                    </div>
                    {sale && (
                        <div className="sale">
                            <del>${saleAmount}</del> <span>{t("save")}: ${saleValue}</span>
                        </div>
                    )}
                </div>
                <div className="title textClip clip-2">
                    <Link to={`../${country}-${lang}/goods/${item.code}`}>{item.name}</Link>
                </div>
                <div className="description textClip clip-1">
                    {item.synonyms}
                </div>
                <Link to={`../${country}-${lang}/goods/${item.code}`}><button> {renderIcon('shop')} {t("add-to-cart")} </button></Link>
            </div>
        </li>
    );
}


const Wall = ({titleH2, grid, arrParam}) => {

    const { country, lang } = useLocale();
    const [ arrWall, setWall ] = useState([]);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!lang) { return; }

        setLoading(true);

        const requestData = {
            lang: lang,
            param: arrParam
        }

        axios
        .post('https://mendeleev.store/core/code/wall/wall.goods.php', requestData, {headers: { 'Content-Type': 'application/json'}})
            .then(response => {
                setWall(response.data);
                setLoading(false);
            }).catch(error => {
                console.error("Error fetching data:", error);
                setLoading(true);
            })
    }, [lang, arrParam]);

    let arrWallArray = [];
    if (Array.isArray(arrWall)) {
        arrWallArray = arrWall;  // Якщо це масив, просто присвоюємо його
    } else if (arrWall && typeof arrWall === 'object') {
        arrWallArray = Object.values(arrWall);  // Якщо це об'єкт, перетворюємо на масив
    } else {
        console.error("arrWall is not an object or array:", arrWall);
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    return(
        <div className="compUIWall">
            <h2>{titleH2}</h2>
            <ul className={grid}>

                {arrWallArray.map((item) => (
                    <WallItem 
                        key = {item.code}
                        item = {item}
                        country = {country}
                        lang = {lang}
                        icoSVG = {icoSVG}
                        t = {t}
                    />
                ))}

            </ul>
        </div>
    );
};

export default Wall;