import React from "react";

import { useTranslation } from "react-i18next";

import CartFixedSide from "../Cart/CartFixedSide";

import './Footer.scss';

const Footer = () => {

    const { t } = useTranslation();

    return(
        <>
            <CartFixedSide />

            <footer>
                <div className="wrap">
                    <div className="logo"><img alt="footer logo" src="../../assets/logo.header.png" /></div>
                    <div className="list">
                        <ul>
                            <li>{t("category")}</li>
                            <li>Chemical products</li>
                            <li>Silicone fluids. PMS Oils</li>
                            <li>Metal Powders and Powders</li>
                            <li>Food additives</li>
                            <li>Perfumery and Cosmetic Raw Materials</li>
                            <li>Laboratory Equipment</li>
                            <li>Grinding Matter</li>
                        </ul>
                    </div>

                    <div className="list">
                        <ul>
                            <li>{t("about-mendeleev")}</li>
                            <li>{t("company-info")}</li>
                            <li>{t("privacy-policy")}</li>
                            <li>{t("information-center")}</li>
                            <li>{t("contact-us")}</li>
                        </ul>
                    </div>

                    <div className="list">
                        <ul>
                            <li>{t("call-center-working-hours")}</li>
                            <li>{t("mon-fri")}</li>
                            <li>{t("sat-fri")}</li>
                        </ul>
                    </div>

                    <div className="list">
                        <ul>
                            <li>{t("stay-connected")}</li>
                            <li>email: hello@mendeleev.store</li>
                            <li>facebook: @mendeleev.store</li>
                        </ul>
                    </div>
                </div>
                <div className="copy">
                    © 2024 Mendeleev.store. All Rights Reserved.
                </div>
                <div className="backgroundGradient-1"></div>
            </footer>
        </>
    );
};

export default Footer;