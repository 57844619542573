import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useLocale } from "../../utils/LocaleContext";

import './PageGoods.scss';

import icosvg from '../../../assets/ico/ico';

import ScrollToTop from "../../utils/ScrollTop";

import PageCaption from "../../ui/PageCaption/PageCaption";
import Wall from "../../ui/Wall/Wall";

const ShopIcon = () => { return( <span className="icon" dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'shop').svg }} /> )}

const PageGoods = () => {

    const { goods } = useParams();
    const { country, lang } = useLocale();
    const [ arrItem, setItem ] = useState([]);
    const [ selectedSku, setSelectedSku ] = useState(null);
    const [ activeImage, setActiveImage ] = useState(null);
    const [ skuInCart, setSkyInCart ] = useState(false);
    const [loading, setLoading] = useState(true);
    
    const { t } = useTranslation();
    

    const navigate = useNavigate();

    useEffect(() => {
        if (!lang) return;

        const currentSKU = new URLSearchParams(window.location.search).get('sku');
        setSelectedSku(currentSKU);

        setLoading(true);
        setSkyInCart(false);
        setItem([]);

        axios
            .get('https://mendeleev.store/core/code/goods/goods.item.php', {
                params: {lang: lang, item: goods}
            })
            .then(response => {
                setItem(response.data);

                if(response.data.sku && !currentSKU){
                    const firstSkuKey = Object.keys(response.data.sku)[0];
                    setSelectedSku(firstSkuKey);
                }

                if(response.data.gallery && Object.keys(response.data.gallery).length > 0) {
                    const firstImage = Object.values(response.data.gallery)[0].href;
                    setActiveImage(firstImage);
                }

                

                setLoading(false); // Завантаження завершено
            }).catch(error => {
                console.error("Error fetching data:", error);
                setLoading(false);
            })
    }, [lang, goods]);

    useEffect(() => {
        if (selectedSku) {
            handleAddToCart(arrItem.ext, selectedSku, 'check-to-sku');
        }
    }, [arrItem.ext, selectedSku]);

    const handleSkuChange  = (sku) => {
        setSelectedSku(sku);
        const currentURL = new URL(window.location);
        currentURL.searchParams.set('sku', sku);
        navigate(currentURL.pathname + currentURL.search, {replace: true});
    }

    const handleImageClick = (href) => {
        setActiveImage(href);
    }

    const handleAddToCart = (ext, sku, check) => {
        let cart = localStorage.getItem('cart_order') ? JSON.parse(localStorage.getItem('cart_order')) : [];
        const existingItemIndex = cart.findIndex(item => item.sku === sku && item.ext === ext);

        if(existingItemIndex === -1 && check === 'add-to-cart'){
            cart.push({
                ext: ext,
                sku: sku,
                quant: 1
            });

            setSkyInCart(true);
        }

        if(existingItemIndex !== -1 && check === 'check-to-sku'){ setSkyInCart(true); }
        if(existingItemIndex === -1 && check === 'check-to-sku'){ setSkyInCart(false); }

        localStorage.setItem('cart_order', JSON.stringify(cart));
    }

    const arrBreadcrumbs = [
        {name: localStorage.getItem("catalog-name"), href: localStorage.getItem("catalog-href")},
        {name: arrItem['name'], href: '/'},
    ];

    if (loading) {
        return <div>Loading...</div>; // Можна поставити будь-який спінер чи повідомлення
    }

    return (
        <div className="pageGoods">
            <ScrollToTop />
            <PageCaption arrHref={arrBreadcrumbs} titleH1={arrItem['name']} />
            <div className="wrap side">
                
                <div className="sideGallery">
                    <div className="icoBig">
                        {activeImage ? (
                            <img alt="" src={activeImage} />
                        ) : (
                            <span></span> // Повідомлення, якщо зображення немає
                        )}
                    </div>
                    <ul>
                        {arrItem.gallery && Object.keys(arrItem.gallery).length > 0 ? (
                            Object.entries(arrItem.gallery).map(([key, item], index) => (
                                <li key={'item-gallery-' + key} className={item.href === activeImage ? 'active' : ''}>
                                    <img alt="" src={item.href} onClick={() => handleImageClick(item.href)} />
                                </li>
                            ))
                        ) : (
                            <li></li> // Виводимо повідомлення, якщо галерея порожня
                        )}
                    </ul>
                </div>

                <div className="sideInfo">

                    {arrItem.sku ? (
                        <div className="sku">
                            <ul>
                                {arrItem.sku && Object.keys(arrItem.sku).length > 0 ? (
                                    Object.entries(arrItem.sku).map(([key, item], index) => {
                                        const [quantity, unit] = item.name ? item.name.match(/^(\d+)(\D+)$/).slice(1) : '';
                                        return (
                                            <li key={'item-sku-' + key} className={key === selectedSku ? 'active' : ''} onClick={() => handleSkuChange(key)}>
                                                <div className="title"><strong>{quantity}</strong> <small>{unit}</small></div>
                                                <div className="price"><sup>$</sup>{item.price}</div>
                                            </li>
                                        );
                                    })
                                ) : (<></>)}
                            </ul>
                        </div>
                    ) : (<></>)}


                    {arrItem['characteristics'].length > 0 || arrItem['description'] ? (
                        <div className="about">
                            <h3>{t("about-the-product")}</h3>

                            {arrItem['characteristics'] ? (
                                <ul>
                                    {arrItem.characteristics && Object.keys(arrItem.characteristics).length > 0 ? (
                                        Object.entries(arrItem.characteristics).map(([key, item], index) => {
                                            return (
                                                <li key={'item-characteristics-' + key}><span>{item.key}</span> <span>{item.value}</span></li>
                                            );
                                        })
                                    ) : (
                                        <li></li>
                                    )}
                                </ul>
                            ) : (<></>)}

                            {arrItem['description'] ? (<div className="text">{arrItem['description']}</div>) : (<></>)}
                            
                        </div>
                    ) : (<></>) }


                </div>
                <div className="sideBuy">
                    <div className="boxBuy">

                        { skuInCart ? (<div className="addedCheck">{t("added-to-cart")}</div>) : (<></>) }
                        
                        <div className="price">
                            <div className="amount"><sup>$</sup>{ selectedSku ? arrItem.sku[selectedSku].price : 0 }</div>

                            { arrItem.sku?.[selectedSku]?.sale > 0 ? (
                                <div className="sale">
                                    <del>${arrItem.sku[selectedSku].amount}</del> <span>{t("save")}: ${arrItem.sku[selectedSku].sale}</span>
                                </div> 
                            ) : (<></>) }
                            
                        </div>

                        <div className="char">
                            <ul>
                                <li><span>{t("shipping")}</span> <span>{t("shipping-text")}</span></li>
                                <li><span>{t("delivery")}</span> <span>{t("delivery-text")}</span></li>
                                <li><span>{t("returns")}</span> <span>{t("returns-text")}</span></li>
                            </ul>
                        </div>

                        { skuInCart ? (
                            <Link to={`/${country}-${lang}/cart`}><button className="added"><ShopIcon/> Go to cart to place an order</button></Link>
                        ) : (
                            <button onClick={() => handleAddToCart(arrItem.ext, selectedSku, 'add-to-cart')}><ShopIcon/> {t('add-to-cart')}</button>
                        )}
                        
                    </div>

                    <div className="text">
                        <ul>
                            <li><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'delivery').svg }} /> <p>{t("delivery-terms")}</p></li>
                            <li><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'diff').svg }} /> <p>{t("return-of-goods")}</p></li>
                            <li><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'card').svg }} /> <p>{t("payment-methods")}</p></li>
                            <li><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'coin').svg }} /> <p>{t("refunds")}</p></li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div className="wrap">
                <Wall titleH2={t("you-may-also-like")} grid={'el5'} />
            </div>
            
        </div>
    );
};

export default PageGoods;