import React from "react";

import './Cart.scss';

import icosvg from '../../../assets/ico/ico';

const CartFixedSide = () => {
    return(
        <div className="compUICart">
            <div className="cartFixedSide">
                <div className="cfsWhiteBox">
                    <div className="caption"><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'shop').svg }}></span> Product added to cart</div>
                    <ul>
                        <li>
                            <div className="icon"></div>
                            <div className="info">
                                <div className="title">
                                    Silicon Carbide Black. Grinding powder 54C F400
                                    <p>Carborundum</p>
                                </div>
                                <div className="sku">
                                    <div className="name">100 gramm</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="ctrl">
                        <button>Continue shopping</button>
                        <button className="cart"><span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'shop').svg }}></span> Go to cart</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CartFixedSide;