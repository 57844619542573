import React, { createContext, useContext, useState } from "react";

const BreadcrumbsContext = createContext();

export const useBreadcrumbs = () => {
    const context = useContext(BreadcrumbsContext);
    if (!context) {
        throw new Error("useBreadcrumbs must be used within a BreadcrumbsProvider");
    }
    return context;
}

export const BreadcrumbsProvider = ({ children }) => {
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const updateBreadcrumbs = (newBreadcrumbs) => {
        if (JSON.stringify(breadcrumbs) !== JSON.stringify(newBreadcrumbs)) {
            setBreadcrumbs(newBreadcrumbs);

            localStorage.setItem('catalog-name', newBreadcrumbs[0]['name']);
            localStorage.setItem('catalog-href', newBreadcrumbs[0]['href']);
        }

    }

    return(
        <BreadcrumbsContext.Provider value={{breadcrumbs, updateBreadcrumbs}}>
            {children}
        </BreadcrumbsContext.Provider>
    );
}
