import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { LocaleProvider } from "../../utils/LocaleContext";
import { useBreadcrumbs } from "../../utils/BreadcrumbsContext";

import './PageCatalog.scss';

import Category from "../../ui/Category/Category";
import Wall from "../../ui/Wall/Wall";
import PageCaption from "../../ui/PageCaption/PageCaption";

import ScrollToTop from "../../utils/ScrollTop";

const PageCatalog = () => {

    const { cat } = useParams();
    const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
    const [ arrWallParam, setArrWallParam  ] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!Array.isArray(breadcrumbs) || breadcrumbs.length === 0) {
            const defaultBreadcrumb = {
                name: localStorage.getItem('catalog-name') || 'Catalog',
                href: localStorage.getItem('catalog-href') || '/catalog/all'
            };
            updateBreadcrumbs([defaultBreadcrumb]);

            setLoading(true);
        }

        const defaultArrWallParam = {
            ops: 'get-catalog-category',
            category_code: cat
        }
        setArrWallParam(defaultArrWallParam);

        setLoading(false);

    }, [breadcrumbs, updateBreadcrumbs, cat]);

    return (
        <LocaleProvider>
            <div className="pageCatalog">
                <ScrollToTop />
                <PageCaption arrHref={breadcrumbs} titleH1={cat} /> 

                <div className="wrap wrapGrid">
                    <div className="sideCategory">
                        <Category />
                    </div>

                    <div className="sideContent">
                        { !loading ? (<Wall titleH2={'All catalog'} grid={'el4'} arrParam={arrWallParam} />) : (<></>) }
                    </div>
                </div>
            </div>
        </LocaleProvider>
    );
};

export default PageCatalog;