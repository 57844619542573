import React, { createContext, useContext, useState, useEffect } from "react";
import i18n from "../../i18n";  // Переконайтеся, що i18n налаштований правильно
import { useParams } from "react-router-dom";

const LocaleContext = createContext();

export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider = ({ children }) => {
    const { local } = useParams();
    const [locale, setLocale] = useState({ country: '', lang: '' });

    useEffect(() => {
        // Якщо параметр local є в URL
        if (local) {
            const [country, lang] = local.split('-');
            setLocale({ country, lang });
            localStorage.setItem('country', country);
            localStorage.setItem('lang', lang);

            // Змінюємо мову в i18n
            i18n.changeLanguage(lang);
        } else {
            // Якщо параметр local відсутній, перевіряємо локаль з localStorage
            const savedCountry = localStorage.getItem('country');
            const savedLang = localStorage.getItem('lang');
            
            // Якщо є збережена країна та мова, використовуємо їх
            if (savedCountry && savedLang) {
                setLocale({ country: savedCountry, lang: savedLang });
                i18n.changeLanguage(savedLang);
            }
        }
    }, [local]);  // Перезапуск useEffect, коли змінюється URL

    return (
        <LocaleContext.Provider value={locale}>
            {children}
        </LocaleContext.Provider>
    );
}
