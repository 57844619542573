import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useLocale } from "../../utils/LocaleContext";
import classNames from "classnames";
import './Header.scss';

import icosvg from '../../../assets/ico/ico';
import { Link } from "react-router-dom";

const HeaderGradient = () => {
    const arrLang = {
        en: 'English',
        ru: 'Russian',
        hr: 'Croatian'
    };

    const { t } = useTranslation();

    const { country, lang } = useLocale();
    const currentLang = lang || localStorage.getItem('lang');
    const [selectedLang, setSelectedLang] = useState(currentLang);
    const logoHref = `${country}-${selectedLang}`;

    const navigate = useNavigate();
    const location = useLocation();

    const changeSwitcher = (newLang) => {
        const currentPath = location.pathname;
        const newPath = currentPath.replace(/(me-)(en|ru|hr)/, `me-${newLang}`);
        navigate(newPath, { replace: true });
        setSelectedLang(newLang);
    };

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 0);
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <header>
            <div className={classNames("headerGradient", { "scrollGradient": isScrolled })}>
                <div className="wrap">
                    <div className="logo">
                        <Link to={`/${logoHref}`}>
                            <img src="../../assets/logo.header.png" alt="logo" />
                        </Link>
                    </div>
                    <div className="language">
                        <div className="select">
                            <div className="value">
                                <span>{arrLang[selectedLang]}</span>
                                <span dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'arrow-n-line').svg }} />
                            </div>
                            <div className="set">
                                <div className="it" onClick={() => changeSwitcher('en')}><span>English</span></div>
                                <div className="it" onClick={() => changeSwitcher('hr')}><span>Croatian</span></div>
                                <div className="it" onClick={() => changeSwitcher('ru')}><span>Russian</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="search">
                        <form action="#" method="GET">
                            <fieldset>
                                <div className="city">{t("country-name")}:</div>
                                <div className="inp">
                                    <input type="text" name="search" defaultValue="" placeholder={t("search-placeholder")} />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <div className="ctrlOrder">
                        <div className="contact">{t("contact-us")}</div>
                        {/* <button dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'scales').svg }}></button> */}
                        <button dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'heart').svg }}></button>
                        <Link to={`/${logoHref}/cart`}><button dangerouslySetInnerHTML={{ __html: icosvg.find(icon => icon.name === 'shop').svg }}></button></Link>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderGradient;
