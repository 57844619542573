import React from "react";
import { LocaleProvider } from "../../utils/LocaleContext";

import Category from "../../ui/Category/Category";
import BannerBox from "../../ui/BannerBox/BannerBox";
import Wall from "../../ui/Wall/Wall";
import CategoryPage from "../../ui/Category/CategoryPage";
import BannerPage from "../../ui/BannerBox/BannerPage";

import './PageHome.scss';

const PageHome = () => {
    console.log('run page home');

    return(
        <div className="wrap">
            <div className="gridCategory">
                <div className="sideCategory"> <LocaleProvider> <Category /> </LocaleProvider> </div>
                <div className="slide">
                    <BannerBox />
                </div>
            </div>

            <div className="cuWall">
                <LocaleProvider><Wall titleH2={'Our new products'} grid={'el5'} /> </LocaleProvider>
            </div>

            {/* <div className="cuWall">
                <Wall titleH2={'Best Sellers in Beauty & Personal Care'} grid={'el5'} />
            </div>

            <div className="cuCategoryPage">
                <CategoryPage />
            </div>

            <div className="cuBannerPage">
                <BannerPage />
            </div>

            <div className="cuWall">
                <Wall titleH2={'Viewed products'} grid={'el5'} />
            </div>

            <div className="cuBannerPage">
                <BannerPage />
            </div>

            <div className="cuWall">
                <Wall titleH2={'You May Also Like'} grid={'el5'} />
            </div> */}
        </div>
    );
};

export default PageHome;